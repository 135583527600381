<template>
    <div v-view="profilHandler"   class="row profil-container" data-aos="fade-up" data-aos-duration="1500">
        <input  class="input-range profil-range" orient="vertical" type="range" v-model="profilKey" min="1" max="6">
        <img
          class="profil"
          src="@/assets/images/import/pr/profil-parfait.png"
          alt="profil"
          data-aos="fade-left" data-aos-duration="1500"
        />
        <template>
          <div class="mobile">
            <img  data-aos="slide-left" data-aos-duration="3000" class="rayures" src="@/assets/images/import/pr/rayures.png" alt="rayures" />
            <div  class="txt-1 " data-aos="slide-left" data-aos-duration="3000">
              <tt-editor v-if="editable" :value="text_1" @input="changeText($event,'text_1')" ></tt-editor>
              <div v-else v-html="text_1">
              </div>
              
            </div>
            <div  class="txt-2" data-aos="slide-left" data-aos-duration="3000">
              <tt-editor v-if="editable" :value="text_2" @input="changeText($event,'text_2')" ></tt-editor>
              <div v-else v-html="text_2">
              </div>
              
            </div>
          </div>

          <div class="computer">
            <img v-if="profilKey >= 2" data-aos="slide-left" data-aos-duration="3000" class="rayures" src="@/assets/images/import/pr/rayures.png" alt="rayures" />
            <div v-if="profilKey >= 4" class="txt-1 " data-aos="slide-left" data-aos-duration="3000"> 
                
                <tt-editor v-if="editable" :value="text_1" @input="changeText($event,'text_1')" ></tt-editor>
              <div v-else v-html="text_1">
              </div>
             </div>
            <div v-if="profilKey >= 6" class="txt-2" data-aos="slide-left" data-aos-duration="3000">
               <tt-editor v-if="editable" :value="text_2" @input="changeText($event,'text_2')" ></tt-editor>
              <div v-else v-html="text_2">
              </div>
            </div>
          </div>

        </template>
    </div>
</template>
<script>
import ttEditor from './tt-editor.vue';

export default {
  name: "profil-pr",
  components:{
    ttEditor
  },
  data() {
    return {
      getdescription: null,
      profilKey:0
    };
  },
  computed: {},
  props:{
    text_1: String,
    text_2: String,
    editable: Boolean,
  },
  watch: {
    justify(data) {
      console.log(data);
    },
  },
  methods: {
    format(description) {
      let justify = description.data["paragraph-justify"];
      return justify;
    },
    saveImage(image) {
      this.$emit("change-image-hb", image);
    },
    typography(j, h) {
      return j + " " + h;
    },
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
    scrollOp(operation){
      var supportsPassive = true;
      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      if (operation) {
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
      }else{
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
      }
    },
    preventDefaultForScrollKeys(e){
      // left: 37, up: 38, right: 39, down: 40,
      // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
      var keys = {37: 1, 38: 1, 39: 1, 40: 1};
      if (keys[e.keyCode]) {
        this.preventDefault(e);
        return false;
      }
    },
    preventDefault(e) {
      e.preventDefault();
    },
    profilHandler(e){
       if (e.percentInView == 1) {
         this.scrollOp(true)
        window.addEventListener('wheel', this.profilScroll)
      }
      
    },
    profilScroll(e){
        if (e.deltaY>0) {
          if (this.profilKey <= 9 ) {
            this.profilKey++
          }
        }else{
          if (this.profilKey > 0) {
            this.profilKey--
          }
        }
        if (this.profilKey >= 9) {
          this.scrollOp(false)
          window.removeEventListener('wheel',this.profilScroll)
          // this.$router.push({ path: '/#section-2' })
        }
    },
    changeText(payload,element){
       this.$emit('CTProfilPrCarriere',{
         'element':element,
         'data':payload
       })
    }
  },
  
};
</script>

<style scoped>
@media screen and (max-width: 992px) {
  /**MOBILE STYLE */

  /**profile section style */
  .profil{
    position: absolute;
    z-index: 1;
    width: 50%;
    top:20px;
    left: 5%;
  }
  .profil-container{
    height: 300px;
    position: relative;
  }

  .rayures {
    position: absolute;
    z-index: -1;
    top: 10%;
    width: 80%;
    right: 0px;
  }

  .txt-1 {
    top: 50px;
    left: 30%;
    text-transform: uppercase;
    font-size: 3rem;
    color: rgb(187, 185, 185);
  }

  .txt-2 {
    top: 30px;
    left: 50%;
    font-family: "Noto Sans";
    color: black;
    font-weight: 600;
    font-size: 1.5rem;
  }
  /***************************/

}

@media screen and (min-width: 992px) {
  /**COMPUTER STYLE */

  /**profile section style */
  .profil-container {
    height: 80vh ;
    position: relative;
  }
  
  .profil {
    position: absolute;
    z-index: 1;
    height: 100%;
    bottom: 0;
    left: 14%;
  }

  .rayures {
    position: absolute;
    z-index: -1;
    top: 10%;
    height: 65%;
    right: 0px;
  }

  .txt-1 {
    top: 30%;
    left: 30%;
    text-transform: uppercase;
    font-size: 5rem;
    color: rgb(187, 185, 185);
  }


  .txt-2 {
    top: 30%;
    left: 60%;
    font-family: "Noto Sans";
    color: black;
    font-weight: 600;
    font-size: 4rem;
  }
  /***************************/
}

.txt-1,
.txt-2 {
  position: absolute;
}
.input-range {
    -webkit-appearance: none;
    height: 200px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #706d7021;
    border-radius: 25px;
    border: 0px solid #000101;
    width: 3px;
    outline: none;
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    transform: rotate(180deg) scaleX(-1);
    cursor: pointer;
    
  }
.profil-range{
    height: 100%;
    margin-left: 20px;
  }
    input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #F93A00;
    height: 3rem;
    width: 5px;
}
</style>